<template>
  <v-container fluid>
    <v-main class="mx-auto">
      <add-material-form
        v-if="newMaterial"
        ref="addForm"
        :loading="loading"
        form-title="Editar material"
        :is-editing="true"
        @save="updateMaterial"
      />
    </v-main>
  </v-container>
</template>

<script>
import AddMaterialForm from '@/components/material/AddMaterialForm'
import { mapActions, mapState } from 'vuex'
import materialService from '@/services/materialService'

export default {
  name: 'Edit',
  components: {
    AddMaterialForm
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState('materials', ['newMaterial'])
  },
  async mounted () {
    await this.fetchCurrentMaterial()
  },
  async beforeDestroy () {
    const to = this.$router.history.current
    if (to.name !== 'MaterialPreview') {
      await this.setNewMaterial(null)
    }
  },
  async beforeRouteLeave (to, from, next) {
    if (to.name === 'MaterialPreview' || !this.$store.state.materials.materialFormIsModified) {
      next()
      return
    }
    const res = confirm('¿Estás seguro? Si sales perderás los cambios no guardados.')
    if (res) {
      next()
    }
  },
  methods: {
    ...mapActions('materials', ['setNewMaterial', 'editMaterial']),
    async fetchCurrentMaterial () {
      const materialId = this.$route.params.id
      if (!this.newMaterial) {
        const currentMaterial = await materialService.getMaterialById(materialId)
        await this.setNewMaterial(currentMaterial)
      }
    },
    async updateMaterial (material) {
      if (this.$refs.addForm.validateForm()) {
        try {
          this.loading = true
          await this.editMaterial(material)
          this.loading = false
          await this.$root.$confirm({
            message: `Se ha modificado '${material.name}' correctamente`,
            showDismissButton: false
          })
          this.$router.push({ name: 'Materials' })
        } catch (e) {
          console.log(e)
          await this.$root.$confirm({
            message: 'Ha ocurrido un error. Inténtelo de nuevo en unos minutos.',
            showDismissButton: false,
            type: 'alert'
          })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
